import type { AppProps } from 'next/app'

import 'swiper/css/bundle'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css/autoplay'

import '../styles/global.css'
import SwiperCore, { Autoplay, Navigation } from 'swiper'

function MyApp({ Component, pageProps }: AppProps) {
  SwiperCore.use([Autoplay, Navigation])
  return <Component {...pageProps} />
}

export default MyApp
